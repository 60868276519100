@import url("@fontsource/red-hat-display");

* {
  font-family: "Red Hat Display";
}

.leaflet-container {
  height: 100vh;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.loader_container {
  display: flex;
  flex-direction: column; /* Aligns children vertically */
  justify-content: center;
  align-items: center;
  height: 100vh; /* Use the full height of the viewport */
  width: 100vw; /* Use the full width of the viewport */
  text-align: center; /* Centers the text horizontally */
}

.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader_text {
  margin-top: 20px; /* Space between loader and text */
  font-size: 22px;
  color: #666666; /* Mid grey, but choose a color that fits your design */
  font-weight: bold;
}

.Select_Tehsil {
  position: absolute;
  z-index: 10000;
  top: 4em;
  left: 64em;
  height: 150px;
  width: 150px;
  background-color: green;
}
.result_div {
  display: flex;
  background: rgba(247, 245, 243, 1);
  width: min-content;
  height: max-content;
  padding: 1rem;
  justify-content: flex-start;
  border-radius: 1rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  gap: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

@media screen and (max-width: 3000px) {
  .result_div {
    width: 350px;
  }
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.UpperBar {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.products {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2em;
  margin-right: 3em;
  gap: 1rem;
}

.Road_Surface {
  display: flex;
  background: rgba(247, 245, 243, 1);
  width: min-content;
  height: max-content;
  padding: 1rem;
  justify-content: flex-start;
  border-radius: 1rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  gap: 2em;
}

.Road_Traffic {
  display: flex;
  background: rgba(247, 245, 243, 1);
  width: max-content;
  padding: 1rem;
  justify-content: flex-start;
  border-radius: 1rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  gap: 2em;
  overflow: hidden;
}

.Traffic_Surface {
  display: flex;
  justify-content: flex-start;
  gap: 2em;
  width: min-content;
}

.RoadText {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@media (max-width: 1200px) {
  .RoadText {
    gap: 0;
  }
}

.Explore_div {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
}
.explore {
  width: 2em;
  height: 2em;
}

.Recent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: auto;
  height: 220px;
  flex-wrap: wrap;
  width: 600px;
}
::-webkit-scrollbar {
  width: 9px; /* Adjust as needed */
}

::-webkit-scrollbar-thumb {
  height: 1px;
  background: rgba(255, 202, 122, 1);

  box-shadow: 4px 4px 36px 0px rgba(0, 0, 0, 0.33);

  box-shadow: -8px -8px 36px 0px rgba(255, 255, 255, 0.48);
}
